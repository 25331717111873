import { Link } from "gatsby";
import React from "react";
import ButtonIcon from "../../images/left-arrow.png";

interface TeammateProps {
  paragraph1?: string;
  paragraph2?: string;
  paragraph3?: string;
}

const Teammate = ({ paragraph1, paragraph2, paragraph3 }: TeammateProps) => {
  return (
    <div className="flex flex-col w-[80%]">
      <Link to="/leadership">
        <div>
          <button className="font-bold text-black pb-5 pt-20 flex flex-row justify-around items-center">
            <img src={ButtonIcon} className="w-4 mr-2" />
            Back to all team
          </button>
        </div>
      </Link>
      <div className="flex lg:flex-row flex-col justify-between pb-32">
        <p className="lg:w-[45%] w-[100%] text-lg">
          {paragraph1}
        </p>
        <p className="lg:w-[45%] w-[100%] lg:mt-0 mt-10 text-lg">
          {paragraph2}
          {paragraph3}
        </p>
      </div>
      <p className="lg:w-[90%] w-[100%] lg:mt-0 mb-20 text-lg">

      </p>
    </div>
  );
};

export default Teammate;
