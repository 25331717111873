import React from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";

interface TeammateProps {
  Name: string;
  Description: string;
  background: string;
  backgroundMobile: string;
  TeammatePhoto: string;
}

const TeammateHeader = ({
  Name,
  Description,
  background,
  backgroundMobile,
  TeammatePhoto,
}: TeammateProps) => {
  const isDesktop = useMediaQuery("(min-width: 1400px)");

  return (
    <div>
      <div className="lg:h-[50vh] h-[65vh] flex flex-col lg:flex-row lg:justify-between w-[80%] mx-auto justify-end items-center">
        {isDesktop ? (
          <img
            className="absolute h-screen lg:h-[50vh] top-0 -z-20 w-full scale-120 object-cover"
            src={background}
          />
        ) : (
          <img
            className="absolute h-screen lg:h-[100vh] top-0 -z-20 w-full scale-120 object-cover"
            src={backgroundMobile}
          />
        )}

        <div className="lg:w-[42%] w-[100%] flex flex-col items-center">
          <h1 className="text-white text-3xl lg:text-5xl font-bold text-center tracking-wide lg:pb-8 lg:mt-32 mt-20">
            {Name}
          </h1>
          <p className="text-slate-500 text-md lg:text-2xl font-light text-center tracking-wide pb-10">
            {Description}
          </p>
        </div>

        <div className="lg:w-[50%] w-[100%] flex items-center justify-center lg:self-end">
          <div className="w-full sm:w-[40%] lg:w-[65%] flex flex-row justify-center">
            <img src={TeammatePhoto} className="lg:min-w-[8rem] max-w-full w-48 sm:min-w-[50%] lg:w-[70%]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammateHeader;
