import React from "react"
import Footer from "../components/Footer/Footer"
import TeammateHeader from "../components/Headers/TeammateHeader/TeammateHeader"
import Navbar from "../components/Navbar/Navbar"

import Background from "../images/teammateBackground.png"
import BackgroundMobile from "../images/teammateBackgroundMobile.png"
import TeammatePhoto from "../images/Owen_Photo.png"
import Teammate from "../components/Teammate/Teammate"

const teammatePage = () => {
    return (
        <div>
            <Navbar />
            <TeammateHeader
                Name="Owen Meyers "
                Description="Developer"
                background={Background}
                backgroundMobile={BackgroundMobile}
                TeammatePhoto={TeammatePhoto}
            />
            <article className="w-[100%] h-auto flex items-center justify-center bg-white">
                <Teammate
                    paragraph1="hi! I'm Owen Meyers, a programmer and passionate engine programmer from North Carolina. I graduated from university in 2022 from the Game Development Bachelor's program and, during my time, developed a game with completely custom engine architecture. I really enjoy working deep within code and am most comfortable surrounded by dense and complex systems."
                    paragraph2="intermetability is important to me as I feel that it will help get a new generation more excited about games. It allows for more magical experiences, where every world is unique but interconnected. Every game becomes a toybox with which to have more fun and interesting experiences."
                />
            </article>
            <Footer />
        </div>
    )
}

export default teammatePage
